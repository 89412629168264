<template>
    <div id="shpbox">
        <div class="shpbox-sec1">
            <div class="basecont">
                <div class="cont">
                    <a href="javascript:;" @click="returncli()" class="reu">
                        <i>
                            <img src="../../assets/images/ltimgawd1.png" alt="">
                        </i>
                        <div class="name">返回购物车</div>
                    </a>
                    <router-link to="/" class="logo"><img src="../../assets/images/logo20.png" alt=""></router-link>
                    <div class="tel">电话：800-843-3269</div>
                </div>
            </div>
        </div>
        <div class="shpbox-sec2 base80">
            <div class="basecont">
                <div class="cont">
                    <div class="lt">
                        <div class="mes_form base100">
                            <div class="ms_tit">
                                <div class="tit">联系信息</div>
                                <div class="name">已经有账户？<router-link to="/sign/scancode">登录</router-link></div>
                            </div>
                            <div class="item">
                                <input type="text" v-model="form.email" placeholder="电子邮件或手机号码">
                            </div>
                            <label class="checkbox">
                                <input type="checkbox" v-model="form.youhui" true-value="成功" false-value="失败">
                                <i></i>
                                <span>让您了解我们最新消息和优惠</span>
                            </label>
                            <div class="ms_tit">
                                <div class="tit">送货地址</div>
                            </div>
                            <div class="f_jut">
                                <div class="item">
                                    <input type="text" v-model="form.ming" placeholder="名">
                                </div>
                                <div class="item">
                                    <input type="text" v-model="form.xing" placeholder="姓">
                                </div>
                            </div>
                            <div class="item">
                                <input type="text" v-model="form.gong" placeholder="公司（可选）">
                            </div>
                            <div class="f_jut">
                                <div class="item">
                                    <select name="" id="" v-model="form.sheng">
                                        <option value="">省份</option>
                                        <option value="广东">广东</option>
                                        <option value="广西">广西</option>
                                        <option value="湖南">湖南</option>
                                    </select>
                                </div>
                                <div class="item">
                                    <select name="" id="" v-model="form.shi">
                                        <option value="">城市</option>
                                        <option value="深圳">深圳</option>
                                        <option value="珠海">珠海</option>
                                        <option value="北京">北京</option>
                                    </select>
                                </div>
                            </div>
                            <div class="item">
                                <select name="" id="" v-model="form.qu">
                                    <option value="">地区</option>
                                    <option value="龙岗">龙岗</option>
                                    <option value="金湾">金湾</option>
                                    <option value="郊区">郊区</option>
                                </select>
                            </div>
                            <div class="item">
                                <input type="text" placeholder="地址" v-model="form.addr">
                            </div>
                            <div class="but">
                                <a href="javascript:;" @click.prevent="formSubmit()" class="hm-but f_buttom">
                                    <span>提交信息</span>
                                    <i>
                                        <img src="../../assets/images/jiantouyo1.png" >
                                        <img src="../../assets/images/jiantouyo2.png" >
                                    </i>
                                </a>
                                <router-link to="/product" class="hm-but f_buttom">
                                    <span>继续购物</span>
                                    <i>
                                        <img src="../../assets/images/jiantouyo1.png" >
                                        <img src="../../assets/images/jiantouyo2.png" >
                                    </i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="gt">
                        <div class="f_nei">
                            <div class="f_tit">订单详情</div>
                            <div class="danzi">
                                <div class="item">
                                    <div class="img">
                                        <img src="../../assets/images/gouruwchanimp1.png">
                                        <i>2</i>
                                    </div>
                                    <div class="font">
                                        <div class="tit">【 BASE KIT 】 PRO DAMPER UCF1</div>
                                        <div class="text">
                                            <p>尺寸：9.5J×21J值</p>
                                            <p>宽度：9.5J×21</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mess">
                                <div class="li">
                                    <span>小计</span>
                                    <span>¥ 112，600</span>
                                </div>
                                <div class="li">
                                    <span>消费税</span>
                                    <span>¥ 11，260</span>
                                </div>
                            </div>
                            <div class="zong">
                                <span>总计</span>
                                <div class="jia">¥ 123，860</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shpbox-sec3">
            <div class="basecont">
                <div class="cont">
                    ©xxxx 版权所有
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Shoppingcart1",
    data(){
        return {
            form:{
                email: "",
                youhui: "",
                ming: "",
                xing: "",
                gong: "",
                sheng: "",
                shi: "",
                qu: "",
                addr: ""
            }
        }
    },
    methods:{
         returncli:function(){  //返回上一页
            this.$router.back();
        },
        formSubmit:function(){
            console.log(this.form);
        }
    }
}
</script>